<template>
  <div v-if="konfiguration" class="mb-3 d-flex flex-wrap">
    {{ konfigurationArrayRequired.length > 0 ? 'Benötigt: ' : '' }}
    <div v-for="configKey in konfigurationArrayRequired" :key="'config-pill-' + configKey[0]">
      <span class="m-1 px-1 rounded-sm bg-warning-o-30">
        {{ $t('KONFIGURATION.' + configKey[0]) }}
      </span>
    </div>
  </div>
  <b-alert v-else show variant="warning">
    <i class="flaticon2-warning text-white"></i> Keine gültige Konfiguration gefunden!</b-alert
  >
</template>

<script>
export default {
  components: {},
  props: {
    konfiguration: { type: Object },
    value: { type: Array },
  },
  data() {
    return {};
  },
  computed: {
    konfigurationArrayRequired() {
      return Object.entries(this.konfiguration).filter(
        ([key, value]) =>
          typeof value == 'boolean' && value === true && key !== 'passdaten' && key !== 'dokumente'
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>
